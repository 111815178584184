<template>
  <div
    class="min-h-dvh bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8"
  >
    <slot />
  </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue'
import { StatusBar, Style } from '@capacitor/status-bar'

const setStatusBarStyle = async () => {
  await StatusBar.setStyle({ style: Style.Light })
  await StatusBar.setBackgroundColor({ color: '#F9FAFB' })
  await StatusBar.setOverlaysWebView({ overlay: false })
  await StatusBar.hide()
}

onMounted(() => {
  void setStatusBarStyle()
})
</script>
